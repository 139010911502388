import React from "react";

import "./style/main.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Landing from "./routes/landing";
import Host from "./routes/host";
import Client from "./routes/client";
import { ToastContainer, toast } from "react-toastify";

import * as Tooltip from "@radix-ui/react-tooltip";

function App() {
  const [playerId, setPlayerId] = React.useState(0);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={<Landing setPlayerId={(id) => setPlayerId(id)} />}
          />
          <Route path="/host" element={<Host />} />
          <Route path="/client" element={<Client playerId={playerId} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
