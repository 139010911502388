import React from "react";
import Ably from "ably";

const Landing = () => {
  const [idValue, setIdValue] = React.useState(0);
  const [nameValue, setNameValue] = React.useState("");

  const [inputValid, setInputValid] = React.useState("");

  const joinGame = async () => {
    const game = {
      id: idValue,
      name: nameValue,
    };

    localStorage.setItem("join-game-as", JSON.stringify(game));
    document.location.href = "/client";
  };

  //Validate input
  React.useEffect(() => {
    if (idValue.length == 4 && nameValue.length > 1 && nameValue.length <= 20) {
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  }, [idValue, nameValue]);

  return (
    <div className="Landing">
      <div className="wrapper">
        <h1>VANDFALD.IO</h1>

        <div className="controls">
          <button
            onClick={() => {
              document.location.href = "/host";
            }}
          >
            Host new game
          </button>
          <div className="seperator">or</div>
          <div className="join">
            <input
              type="text"
              placeholder="Enter your nickname"
              onChange={(e) => setNameValue(e.target.value)}
            />
            <input
              type="number"
              placeholder="Enter game ID"
              onChange={(e) => setIdValue(e.target.value)}
              maxLength={4}
              minLength={4}
            />
            <button
              onClick={() => joinGame()}
              disabled={inputValid ? false : true}
            >
              Join existing game
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
