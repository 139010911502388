import twoofclubs from "./2_of_clubs.png";
import twoofdiamonds from "./2_of_diamonds.png";
import twoofhearts from "./2_of_hearts.png";
import twoofspades from "./2_of_spades.png";
import threeofclubs from "./3_of_clubs.png";
import threeofdiamonds from "./3_of_diamonds.png";
import threeofhearts from "./3_of_hearts.png";
import threeofspades from "./3_of_spades.png";
import fourofclubs from "./4_of_clubs.png";
import fourofdiamonds from "./4_of_diamonds.png";
import fourofhearts from "./4_of_hearts.png";
import fourofspades from "./4_of_spades.png";
import fiveofclubs from "./5_of_clubs.png";
import fiveofdiamonds from "./5_of_diamonds.png";
import fiveofhearts from "./5_of_hearts.png";
import fiveofspades from "./5_of_spades.png";
import sixofclubs from "./6_of_clubs.png";
import sixofdiamonds from "./6_of_diamonds.png";
import sixofhearts from "./6_of_hearts.png";
import sixofspades from "./6_of_spades.png";
import sevenofclubs from "./7_of_clubs.png";
import sevenofdiamonds from "./7_of_diamonds.png";
import sevenofhearts from "./7_of_hearts.png";
import sevenofspades from "./7_of_spades.png";
import eightofclubs from "./8_of_clubs.png";
import eightofdiamonds from "./8_of_diamonds.png";
import eightofhearts from "./8_of_hearts.png";
import eightofspades from "./8_of_spades.png";
import nineofclubs from "./9_of_clubs.png";
import nineofdiamonds from "./9_of_diamonds.png";
import nineofhearts from "./9_of_hearts.png";
import nineofspades from "./9_of_spades.png";
import tenofclubs from "./10_of_clubs.png";
import tenofdiamonds from "./10_of_diamonds.png";
import tenofhearts from "./10_of_hearts.png";
import tenofspades from "./10_of_spades.png";
import jackofclubs from "./jack_of_clubs2.png";
import jackofdiamonds from "./jack_of_diamonds2.png";
import jackofhearts from "./jack_of_hearts2.png";
import jackofspades from "./jack_of_spades2.png";
import queenofclubs from "./queen_of_clubs2.png";
import queenofdiamonds from "./queen_of_diamonds2.png";
import queenofhearts from "./queen_of_hearts2.png";
import queenofspades from "./queen_of_spades2.png";
import kingofclubs from "./king_of_clubs2.png";
import kingofdiamonds from "./king_of_diamonds2.png";
import kingofhearts from "./king_of_hearts2.png";
import kingofspades from "./king_of_spades2.png";
import aceofclubs from "./ace_of_clubs.png";
import aceofdiamonds from "./ace_of_diamonds.png";
import aceofhearts from "./ace_of_hearts.png";
import aceofspades from "./ace_of_spades.png";

// hearts: red,
// diamonds: red,
// clubs: black,
// spades: black,

const SIPS_CARD = "Sips card";
const PAUSE_CARD = {
  rule: "Pause card",
  ruleDesc:
    "You can use this card if you have to piss / take a break. You can also trade this card",
};
const CATEGORY_CARD = {
  rule: "Category card",
  ruleDesc:
    "You have to come up with a category (e.g. Fast food, types of alcohol or cars), and then every everyone has to say something within the category. You start.",
};
const FINGER_CARD = {
  rule: "Finger-on-your-nose card",
  ruleDesc:
    "When you use this card, you have to place a finger on your nose. Everyone else has to do the same, last person to do so, looses, and has to take a sip.",
};
const RULE_CARD = {
  rule: "Rule card",
  ruleDesc:
    "You have to come up with a rule (e.g every time x drinks, y has to drink). Everyone has to obay the rule, kings can decide not to.",
};
const WATERFALL_CARD = {
  rule: "Waterfall card!",
  ruleDesc:
    "Everyone has to start drinking, you can only stop drinking when the person before you has stopped drinking. You start.",
};
const BOYS_CARD = { rule: "Boys drink card", ruleDesc: "All boys take a sip" };
const GIRLS_CARD = {
  rule: "Girls drink card",
  ruleDesc: "All girls take a sip",
};
const KING_CARD = {
  rule: "King card!",
  ruleDesc:
    "You are the king for the next 15 minutes. You have full autority over the game, and everyone has to do everything you say.",
};
const SNIPER_CARD = {
  rule: "Sniper card",
  ruleDesc:
    "When you use this card, you have to make a circle with your fingers, and put it up to your eye. Then you have to look at someone, and count up from one out loud. When the person you looked at shouts 'STOP', you have to stop, and then the person has to take the amount of drinks that you counted to. You can trade this card.",
};

const cards = [
  {
    id: 0,
    name: "2 of clubs",
    image: twoofclubs,
    rule: SIPS_CARD,
    ruleDesc: "Take 2 sips",
  },
  {
    id: 1,
    name: "2 of diamonds",
    image: twoofdiamonds,
    rule: SIPS_CARD,
    ruleDesc: "Give someone 2 sips",
  },
  {
    id: 2,
    name: "2 of hearts",
    image: twoofhearts,
    rule: SIPS_CARD,
    ruleDesc: "Give someone 2 sips",
  },
  {
    id: 3,
    name: "2 of spades",
    image: twoofspades,
    rule: SIPS_CARD,
    ruleDesc: "Take 2 sips",
  },
  {
    id: 4,
    name: "3 of clubs",
    image: threeofclubs,
    rule: SIPS_CARD,
    ruleDesc: "Take 3 sips",
  },
  {
    id: 5,
    name: "3 of diamonds",
    image: threeofdiamonds,
    rule: SIPS_CARD,
    ruleDesc: "Give someone 3 sips",
  },
  {
    id: 6,
    name: "3 of hearts",
    image: threeofhearts,
    rule: SIPS_CARD,
    ruleDesc: "Give someone 3 sips",
  },
  {
    id: 7,
    name: "3 of spades",
    image: threeofspades,
    rule: SIPS_CARD,
    ruleDesc: "Take 3 sips",
  },
  {
    id: 8,
    name: "4 of clubs",
    image: fourofclubs,
    rule: SIPS_CARD,
    ruleDesc: "Take 4 sips",
  },
  {
    id: 9,
    name: "4 of diamonds",
    image: fourofdiamonds,
    rule: SIPS_CARD,
    ruleDesc: "Give someone 4 sips",
  },
  {
    id: 10,
    name: "4 of hearts",
    image: fourofhearts,
    rule: SIPS_CARD,
    ruleDesc: "Give someone 4 sips",
  },
  {
    id: 11,
    name: "4 of spades",
    image: fourofspades,
    rule: SIPS_CARD,
    ruleDesc: "Take 4 sips",
  },
  {
    id: 12,
    name: "5 of clubs",
    image: fiveofclubs,
    rule: SIPS_CARD,
    ruleDesc: "Take 5 sips",
  },
  {
    id: 13,
    name: "5 of diamonds",
    image: fiveofdiamonds,
    rule: SIPS_CARD,
    ruleDesc: "Give someone 5 sips",
  },
  {
    id: 14,
    name: "5 of hearts",
    image: fiveofhearts,
    rule: SIPS_CARD,
    ruleDesc: "Give someone 5 sips",
  },
  {
    id: 15,
    name: "5 of spades",
    image: fiveofspades,
    rule: SIPS_CARD,
    ruleDesc: "Take 5 sips",
  },
  {
    id: 16,
    name: "6 of clubs",
    image: sixofclubs,
    rule: PAUSE_CARD.rule,
    ruleDesc: PAUSE_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 17,
    name: "6 of diamonds",
    image: sixofdiamonds,
    rule: PAUSE_CARD.rule,
    ruleDesc: PAUSE_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 18,
    name: "6 of hearts",
    image: sixofhearts,
    rule: PAUSE_CARD.rule,
    ruleDesc: PAUSE_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 19,
    name: "6 of spades",
    image: sixofspades,
    rule: PAUSE_CARD.rule,
    ruleDesc: PAUSE_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 20,
    name: "7 of clubs",
    image: sevenofclubs,
    rule: CATEGORY_CARD.rule,
    ruleDesc: CATEGORY_CARD.ruleDesc,
  },
  {
    id: 21,
    name: "7 of diamonds",
    image: sevenofdiamonds,
    rule: CATEGORY_CARD.rule,
    ruleDesc: CATEGORY_CARD.ruleDesc,
  },
  {
    id: 22,
    name: "7 of hearts",
    image: sevenofhearts,
    rule: CATEGORY_CARD.rule,
    ruleDesc: CATEGORY_CARD.ruleDesc,
  },
  {
    id: 23,
    name: "7 of spades",
    image: sevenofspades,
    rule: CATEGORY_CARD.rule,
    ruleDesc: CATEGORY_CARD.ruleDesc,
  },
  {
    id: 24,
    name: "8 of clubs",
    image: eightofclubs,
    rule: FINGER_CARD.rule,
    ruleDesc: FINGER_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 25,
    name: "8 of diamonds",
    image: eightofdiamonds,
    rule: FINGER_CARD.rule,
    ruleDesc: FINGER_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 26,
    name: "8 of hearts",
    image: eightofhearts,
    rule: FINGER_CARD.rule,
    ruleDesc: FINGER_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 27,
    name: "8 of spades",
    image: eightofspades,
    rule: FINGER_CARD.rule,
    ruleDesc: FINGER_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 28,
    name: "9 of clubs",
    image: nineofclubs,
    rule: RULE_CARD.rule,
    ruleDesc: RULE_CARD.ruleDesc,
  },
  {
    id: 29,
    name: "9 of diamonds",
    image: nineofdiamonds,
    rule: RULE_CARD.rule,
    ruleDesc: RULE_CARD.ruleDesc,
  },
  {
    id: 30,
    name: "9 of hearts",
    image: nineofhearts,
    rule: RULE_CARD.rule,
    ruleDesc: RULE_CARD.ruleDesc,
  },
  {
    id: 31,
    name: "9 of spades",
    image: nineofspades,
    rule: RULE_CARD.rule,
    ruleDesc: RULE_CARD.ruleDesc,
  },
  {
    id: 32,
    name: "10 of clubs",
    image: tenofclubs,
    rule: WATERFALL_CARD.rule,
    ruleDesc: WATERFALL_CARD.ruleDesc,
  },
  {
    id: 33,
    name: "10 of diamonds",
    image: tenofdiamonds,
    rule: WATERFALL_CARD.rule,
    ruleDesc: WATERFALL_CARD.ruleDesc,
  },
  {
    id: 34,
    name: "10 of hearts",
    image: tenofhearts,
    rule: WATERFALL_CARD.rule,
    ruleDesc: WATERFALL_CARD.ruleDesc,
  },
  {
    id: 35,
    name: "10 of spades",
    image: tenofspades,
    rule: WATERFALL_CARD.rule,
    ruleDesc: WATERFALL_CARD.ruleDesc,
  },
  {
    id: 36,
    name: "Jack of clubs",
    image: jackofclubs,
    rule: BOYS_CARD.rule,
    ruleDesc: BOYS_CARD.ruleDesc,
  },
  {
    id: 37,
    name: "Jack of diamonds",
    image: jackofdiamonds,
    rule: BOYS_CARD.rule,
    ruleDesc: BOYS_CARD.ruleDesc,
  },
  {
    id: 38,
    name: "Jack of hearts",
    image: jackofhearts,
    rule: BOYS_CARD.rule,
    ruleDesc: BOYS_CARD.ruleDesc,
  },
  {
    id: 39,
    name: "Jack of spades",
    image: jackofspades,
    rule: BOYS_CARD.rule,
    ruleDesc: BOYS_CARD.ruleDesc,
  },
  {
    id: 40,
    name: "Queen of clubs",
    image: queenofclubs,
    rule: GIRLS_CARD.rule,
    ruleDesc: GIRLS_CARD.ruleDesc,
  },
  {
    id: 41,
    name: "Queen of diamonds",
    image: queenofdiamonds,
    rule: GIRLS_CARD.rule,
    ruleDesc: GIRLS_CARD.ruleDesc,
  },
  {
    id: 42,
    name: "Queen of hearts",
    image: queenofhearts,
    rule: GIRLS_CARD.rule,
    ruleDesc: GIRLS_CARD.ruleDesc,
  },
  {
    id: 43,
    name: "Queen of spades",
    image: queenofspades,
    rule: GIRLS_CARD.rule,
    ruleDesc: GIRLS_CARD.ruleDesc,
  },
  {
    id: 44,
    name: "King of clubs",
    image: kingofclubs,
    rule: KING_CARD.rule,
    ruleDesc: KING_CARD.ruleDesc,
  },
  {
    id: 45,
    name: "King of diamonds",
    image: kingofdiamonds,
    rule: KING_CARD.rule,
    ruleDesc: KING_CARD.ruleDesc,
  },
  {
    id: 46,
    name: "King of hearts",
    image: kingofhearts,
    rule: KING_CARD.rule,
    ruleDesc: KING_CARD.ruleDesc,
  },
  {
    id: 47,
    name: "King of spades",
    image: kingofspades,
    rule: KING_CARD.rule,
    ruleDesc: KING_CARD.ruleDesc,
  },
  {
    id: 48,
    name: "Ace of clubs",
    image: aceofclubs,
    rule: SNIPER_CARD.rule,
    ruleDesc: SNIPER_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 49,
    name: "Ace of diamonds",
    image: aceofdiamonds,
    rule: SNIPER_CARD.rule,
    ruleDesc: SNIPER_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 50,
    name: "Ace of hearts",
    image: aceofhearts,
    rule: SNIPER_CARD.rule,
    ruleDesc: SNIPER_CARD.ruleDesc,
    keepable: true,
  },
  {
    id: 51,
    name: "Ace of spades",
    image: aceofspades,
    rule: SNIPER_CARD.rule,
    ruleDesc: SNIPER_CARD.ruleDesc,
    keepable: true,
  },
];

export default cards;
