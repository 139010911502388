import React from "react";
import Ably from "ably";
import "./landing.scss";
import "../style/game.scss";

import logo from "../assets/images/logo.png";
import leave from "../assets/icons/leave.svg";
import trade from "../assets/icons/tradecard.svg";
import use from "../assets/icons/usecard.svg";
import skip from "../assets/icons/skip.svg";
import kingBlack from "../assets/icons/king_black.svg";

import GameControl from "../components/GameControl/GameControl";

const Client = () => {
  const [ably, setAbly] = React.useState(null);
  const [channel, setChannel] = React.useState(null);

  const [playerId, setPlayerId] = React.useState(null);
  const [currentPlayer, setCurrentPlayer] = React.useState(null);
  const [game, setGame] = React.useState({
    gameStarted: false,
    players: [],
    currentCard: undefined,
    id: undefined,
  });

  const [selectedCard, setSelectedCard] = React.useState(undefined);
  const [tradeModal, setTradeModal] = React.useState(false);

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    const localPlayer = localStorage.getItem("join-game-as");
    const player = JSON.parse(localPlayer);

    const connect = async () => {
      const newAbly = new Ably.Realtime.Promise(
        "7Yi1kA.JF1pjA:T7CxvhWzNwnjB7BDeKDxVcVaBRZsT390iYxuvenzoQg"
      );
      await newAbly.connection.once("connected");
      const newChannel = newAbly.channels.get(`game-${player.id}`);
      console.log(`game-${player.id}`);

      newChannel.publish("addPlayer", player.name);

      setChannel(newChannel);
      setAbly(newAbly);
      setPlayerId(newAbly.connection.id);
      console.log("Connected!");
    };
    connect();
  }, []);

  React.useEffect(() => {
    if (channel) {
      channel.subscribe("gameUpdate", (msg) => {
        console.log(msg);
        setGame(msg.data);
        setCurrentPlayer(
          msg.data.players.filter((player) => player.id === playerId)[0]
        );

        if (msg.data.state === "gameOver") {
          setSelectedCard(undefined);
        }
      });

      return () => {
        channel.unsubscribe("gameUpdate");
      };
    }
  }, [channel]);

  React.useEffect(() => {
    console.log(game);
  }, [currentPlayer]);

  const pickCard = () => {
    channel.publish("pickCard", "pickCard");
  };

  const finishTurn = () => {
    channel.publish("finishTurn", "finishTurn");
  };

  const leaveGame = () => {
    channel.publish("leaveGame", "leaveGame");
    document.location.href = "/";
  };

  const activateCard = (card) => {
    channel.publish("useCard", card);
    setSelectedCard(undefined);
  };

  const tradeCard = (player, card) => {
    channel.publish("tradeCard", { player: player, card: card });
    setTradeModal(false);
    setSelectedCard(undefined);
  };

  return (
    <div className="client">
      <img src={logo} alt="" className="watermark" />
      {currentPlayer && (
        <div className="Game">
          <div className="wrapper">
            <div className="state">
              {/* Color to be changed */}
              <div
                className="player"
                style={{
                  "--player-color": `${
                    game.gameStarted
                      ? game.state !== "gameOver"
                        ? game.players[game.currentPlayerIndex].color
                        : "var(--brand)"
                      : currentPlayer.color
                  }`,
                }}
              >
                {game.gameStarted ? (
                  <>
                    {game.state === "pickCard" ? (
                      <>
                        <h1>
                          It's{" "}
                          {game.players[game.currentPlayerIndex].id ===
                          currentPlayer.id
                            ? "your"
                            : `${
                                game.players[game.currentPlayerIndex].name
                              }'s`}{" "}
                          turn
                        </h1>
                      </>
                    ) : game.state === "cardPicked" ? (
                      <>
                        <p>
                          {game.players[game.currentPlayerIndex].id ===
                          currentPlayer.id
                            ? "You"
                            : game.players[game.currentPlayerIndex].name}{" "}
                          picked
                        </p>
                        <h1>{game.currentCard.name}</h1>
                      </>
                    ) : (
                      <>
                        <h1>Game over!</h1>
                        <p>Waiting for host to restart...</p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <h1>You're connected as {currentPlayer.name}</h1>
                    <p>Waiting for host to start...</p>
                  </>
                )}
              </div>
              {game.gameStarted && (
                <>
                  {game.players[game.currentPlayerIndex].id ===
                    currentPlayer.id && (
                    <>
                      {game.state !== "gameOver" && (
                        <>
                          {game.state === "pickCard" ? (
                            <>
                              <button
                                onClick={() => pickCard()}
                                style={{
                                  background:
                                    game.players[game.currentPlayerIndex].color,
                                }}
                              >
                                Pick card
                              </button>
                            </>
                          ) : (
                            <>
                              <div
                                className="rule"
                                style={{
                                  "--player-color":
                                    game.players[game.currentPlayerIndex].color,
                                }}
                              >
                                <p>{game.currentCard.ruleDesc}</p>
                              </div>
                              <img
                                src={game.currentCard.image}
                                alt={game.currentCard.name}
                                className="cardImg"
                              />
                              <button
                                onClick={() => finishTurn()}
                                style={{
                                  background:
                                    game.players[game.currentPlayerIndex].color,
                                }}
                              >
                                Finish turn
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          {game.state !== "gameOver" && (
            <div className="playerCards">
              {currentPlayer.cards.map((card, index) => (
                <>
                  {index !== 0 && (
                    <div
                      className={`card ${selectedCard ? "" : "hover"} ${
                        index == selectedCard ? "selected" : ""
                      }`}
                      onClick={() => {
                        if (selectedCard == index) {
                          setSelectedCard(undefined);
                        } else {
                          setSelectedCard(index);
                        }
                      }}
                    >
                      <img src={card.image} alt="" />
                    </div>
                  )}
                </>
              ))}
            </div>
          )}

          {tradeModal && (
            <div className="trade-modal">
              <div
                className="modal-bg"
                onClick={() => setTradeModal(false)}
              ></div>
              <div className="modal-content">
                <h2>Select player</h2>
                <div className="players-list">
                  {game.players.map((player, index) => (
                    <>
                      {player.id !== currentPlayer.id && (
                        <div className="player-to-trade">
                          <button
                            style={{ background: player.color }}
                            onClick={() =>
                              tradeCard(
                                player,
                                currentPlayer.cards[selectedCard]
                              )
                            }
                          >
                            Trade card with {player.name}
                          </button>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="controls">
            {selectedCard && (
              <>
                <GameControl
                  className="use-card"
                  onClick={() =>
                    activateCard(currentPlayer.cards[selectedCard])
                  }
                  icon={use}
                  tooltip="Use selected card"
                />

                <GameControl
                  className="trade-card"
                  onClick={() => setTradeModal(true)}
                  icon={trade}
                  tooltip="Trade selcted card"
                />
              </>
            )}

            {game.players[game.currentPlayerIndex].id === currentPlayer.id &&
              game.state !== "gameOver" && (
                <GameControl
                  className="skip"
                  onClick={() => channel.publish("finishTurn", "finishTurn")}
                  icon={skip}
                  tooltip="Skip turn"
                />
              )}

            <GameControl
              className="leave"
              onClick={() => leaveGame()}
              icon={leave}
              tooltip="Leave the game"
            />

            {currentPlayer.isKing && (
              <GameControl
                info
                className="isKing"
                icon={kingBlack}
                text={`You are the king!`}
                tooltip={`${currentPlayer.isKing} minutes left`}
              />
            )}
          </div>
        </div>
      )}

      {/*<h1>Client</h1>
      {game.gameStarted ? (
        <div className="game">
          {game.state !== "gameOver" ? (
            <>
              <p>Game started!</p>
              {currentPlayer.isKing && (
                <p>You are the king for {currentPlayer.isKing} minutes.</p>
              )}
              {currentPlayer.playersTurn ? (
                <>
                  <p>It's your turn.</p>
                  {game.state === "pickCard" ? (
                    <>
                      <button onClick={() => pickCard()}>Pick card</button>
                    </>
                  ) : (
                    <>
                      <p>You picked a {game.currentCard.name}</p>
                      <button onClick={() => finishTurn()}>Finish turn</button>
                    </>
                  )}
                </>
              ) : (
                <p>It's not your turn.</p>
              )}
            </>
          ) : (
            <>
              <p>Game over! Waiting for host to restart</p>
            </>
          )}
          <div className="cards">
            <h3>Your cards</h3>
            {currentPlayer.cards.map((card, index) => (
              <>
                <div className="card">
                  <p>{card.rule}</p>
                  <button onClick={() => activateCard(card)}>Use</button>
                  <button>Trade</button>
                </div>
              </>
            ))}
          </div>
        </div>
      ) : (
        <p>Game is not started...</p>
      )}
      <button onClick={() => leaveGame()}>Leave Game</button>*/}
    </div>
  );
};

export default Client;
