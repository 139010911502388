import React from "react";

const Toast = (props) => {
  const container = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      container.current.remove();
    }, 5000);
  }, []);

  return (
    <div className="toast" ref={container}>
      {props.text}
    </div>
  );
};

export default Toast;
