import React from "react";
import Ably from "ably";
import "./host.scss";
import "../style/game.scss";

import cards from "../assets/cards";
import colors from "../assets/colors.json";
import * as Tooltip from "@radix-ui/react-tooltip";
import GameControl from "../components/GameControl/GameControl";

import cardssvg from "../assets/icons/cards.svg";
import cross from "../assets/icons/cross.svg";
import dashboard from "../assets/icons/dashboard.svg";
import kingWhite from "../assets/icons/king_white.svg";
import kingBlack from "../assets/icons/king_black.svg";
import qr from "../assets/icons/qr.svg";
import reload from "../assets/icons/reload.svg";
import skip from "../assets/icons/skip.svg";

import logo from "../assets/images/logo.png";
import Toast from "../components/Toast/Toast";

const Host = () => {
  const [ably, setAbly] = React.useState(null);
  const [channel, setChannel] = React.useState(null);
  const [int, setInt] = React.useState(0);
  const [currentKing, setCurrentKing] = React.useState(undefined);
  const [kingTimeout, setKingTimeout] = React.useState(undefined);
  const [toasts, setToasts] = React.useState([]);

  const [showLobby, setShowLobby] = React.useState(true);

  // Game state, keeps track of the current game, players and cards.
  const [game, setGame] = React.useState({
    players: [],
    currentPlayerIndex: 0,
    currentCard: undefined,
    usedCards: [],
    gameStarted: false,
    id: "0000",
    state: undefined,
    settings: {
      kingTimeout: 15,
      colorPack: colors.default,
    },
  });
  const [activePlayer, setActivePlayer] = React.useState(undefined);

  const generateId = () => {
    let randomNumber = Math.floor(Math.random() * 10000);
    let formattedNumber = randomNumber.toString().padStart(4, "0");
    return formattedNumber;
  };

  const getRandomCard = () => {
    let num = Math.floor(Math.random() * 52);
    //check if card has been used
    let exists = game.usedCards.filter((card) => card == num);

    while (exists[0]) {
      num = Math.floor(Math.random() * 52);
      exists = game.usedCards.filter((card) => card == num);
    }

    return cards[num];
  };

  const removePlayer = (id) => {
    let newGame = { ...game };
    let players = newGame.players;
    newGame.players = players.filter((player) => player.id !== id);
    setGame(newGame);
  };

  //King interval
  React.useEffect(() => {
    if (currentKing) {
      let interval = setInterval(() => {
        let newGame = { ...game };
        newGame.players.forEach((player, index) => {
          if (player.id === currentKing) {
            if (newGame.players[index].isKing == 0) {
              setCurrentKing(undefined);
              setKingTimeout(undefined);
            } else {
              setKingTimeout({
                color: newGame.players[index].color,
                id: newGame.players[index].id,
                player: newGame.players[index].name,
                timeLeft: newGame.players[index].isKing - 1,
              });
            }
          }
        });
      }, 60000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [currentKing]);

  React.useEffect(() => {
    if (kingTimeout) {
      console.log(currentKing);
      let newGame = { ...game };
      newGame.players.forEach((player, index) => {
        if (player.id === currentKing) {
          if (kingTimeout.timeLeft == 0) {
            newGame.players[index].isKing = false;
          } else {
            newGame.players[index].isKing = kingTimeout.timeLeft;
          }
        }
      });

      setGame(newGame);
    }
  }, [kingTimeout]);

  React.useEffect(() => {
    const connect = async () => {
      const newAbly = new Ably.Realtime.Promise(
        "7Yi1kA.JF1pjA:T7CxvhWzNwnjB7BDeKDxVcVaBRZsT390iYxuvenzoQg"
      );
      await newAbly.connection.once("connected");
      setAbly(newAbly);
      console.log("Connected!");
    };
    connect();
  }, []);

  React.useEffect(() => {
    if (ably && game.id) {
      setChannel(ably.channels.get(`game-${game.id}`));
      console.log();
      console.log(`Connected to game: "game-${game.id}"`);
    }
  }, [ably, game.id]);

  React.useEffect(() => {
    if (!game.id) {
      const newId = generateId();
      setGame({ ...game, id: newId });
    }
  }, [game.id]);

  // Ably subscriptions
  React.useEffect(() => {
    if (channel) {
      channel.subscribe("testPush", (message) => {
        console.log("Recieved message: " + message);
      });

      channel.subscribe("addPlayer", (message) => {
        setGame({
          ...game,
          players: [
            ...game.players,
            {
              id: message.connectionId,
              name: message.data,
              cards: [cards[0]],
              playersTurn: false,
              isKing: false,
              color: game.settings.colorPack[game.players.length],
            },
          ],
        });
      });

      channel.subscribe("test", (message) => {
        console.log(int);
      });

      channel.subscribe("pickCard", (msg) => {
        const card = getRandomCard();
        //const card = cards[50];
        let newGame = { ...game };
        let players = newGame.players;
        let king;
        if (card.id >= 44 && card.id <= 47) {
          //Remove old king
          players.forEach((player, index) => {
            if (player.isKing) {
              players[index].isKing = false;
            }
          });

          //Set new king
          players.forEach((player, index) => {
            if (player.id === msg.connectionId) {
              players[index].isKing = game.settings.kingTimeout;
              king = players[index];
            }
          });
          setCurrentKing(msg.connectionId);
          setKingTimeout({
            color: king.color,
            id: king.id,
            player: king.name,
            timeLeft: game.settings.kingTimeout,
          });
        }

        if (card.keepable == true) {
          players.forEach((player, index) => {
            if (player.id === msg.connectionId) {
              players[index].cards = [...players[index].cards, card];
            }
          });
        }

        setGame({
          ...game,
          currentCard: card,
          state: "cardPicked",
          usedCards: [...game.usedCards, card.id],
          players: players,
        });
      });

      channel.subscribe("finishTurn", (msg) => {
        let newGame = { ...game };

        //if (cards.length == game.usedCards.length) {
        if (game.usedCards.length === cards.length) {
          newGame.state = "gameOver";
        } else {
          let index = newGame.currentPlayerIndex;
          if (newGame.players[index + 1]) {
            newGame.players[index].playersTurn = false;
            newGame.players[index + 1].playersTurn = true;
            newGame.currentPlayerIndex = index + 1;
          } else {
            newGame.players[index].playersTurn = false;
            newGame.players[0].playersTurn = true;
            newGame.currentPlayerIndex = 0;
          }

          newGame.state = "pickCard";
        }

        setGame(newGame);
      });

      channel.subscribe("leaveGame", (msg) => {
        removePlayer(msg.connectionId);
      });

      channel.subscribe("useCard", (msg) => {
        let newGame = { ...game };
        let players = newGame.players;
        let player = game.players.filter(
          (player) => player.id == msg.connectionId
        )[0];
        console.log(msg.data.id);
        let cardFiltered = player.cards.filter(
          (card) => card.id !== msg.data.id
        );
        console.log("cardFiltered", cardFiltered);

        players.forEach((player, index) => {
          console.log(player.id);
          if (player.id === msg.connectionId) {
            console.log(player.id, "AAAAAAAAAAA");
            players[index].cards = cardFiltered;
          }
        });

        toast(`${player.name} used a ${msg.data.rule}`);
        setGame({ ...game, players: players });
      });

      channel.subscribe("tradeCard", (msg) => {
        let players = [...game.players];

        let playerFrom;
        let playerTo;

        players.forEach((player, index) => {
          if (player.id === msg.connectionId) {
            playerFrom = player;
            player.cards = player.cards.filter(
              (card) => card.id !== msg.data.card.id
            );
          }

          if (player.id === msg.data.player.id) {
            playerTo = player;
            player.cards.push(msg.data.card);
          }
        });

        toast(
          `${playerFrom.name} traded a ${msg.data.card.rule} to ${playerTo.name}`
        );
        setGame({ ...game, players: players });
      });

      return () => {
        channel.unsubscribe("test");
        channel.unsubscribe("addPlayer");
        channel.unsubscribe("pickCard");
        channel.unsubscribe("finishTurn");
        channel.unsubscribe("leaveGame");
        channel.unsubscribe("useCard");
        channel.unsubscribe("tradeCard");
      };
    }
  }, [channel, int, game]);

  React.useEffect(() => {
    console.log(game);
    if (game && channel) {
      channel.publish("gameUpdate", game);
    }
  }, [game, channel]);

  const startGame = () => {
    let newGame = { ...game };
    newGame.gameStarted = true;
    newGame.players[0].playersTurn = true;
    newGame.state = "pickCard";
    newGame.currentPlayerIndex = 0;
    console.log(newGame);
    setShowLobby(false);

    setActivePlayer(newGame.players[0]);
    setGame(newGame);
  };

  const restartGame = () => {
    let newGame = { ...game };
    newGame.state = undefined;
    newGame.currentCard = undefined;
    newGame.gameStarted = false;
    newGame.usedCards = [];
    newGame.players.forEach((player, index) => {
      newGame.players[index].cards = [];
      newGame.players[index].isKing = false;
      newGame.players[index].playersTurn = false;
    });

    setShowLobby(true);
    setGame(newGame);
  };

  const toast = (msg) => {
    setToasts([...toasts, msg]);
  };

  return (
    <div className="Host">
      <div className="toastContainer">
        {toasts.map((toast) => (
          <Toast text={toast} />
        ))}
      </div>
      <img src={logo} alt="" className="watermark" />
      {showLobby && (
        <>
          <div className="Lobby">
            <div className="wrapper">
              <div className="col info">
                <div className="id">
                  <div className="game-id">
                    <p>Game PIN</p>
                    <h1 className="pin-number">{game.id}</h1>
                  </div>
                  <div className="qr">qr uwu</div>
                </div>

                {game.gameStarted ? (
                  <button onClick={() => setShowLobby(false)}>
                    Back to game
                  </button>
                ) : (
                  <button
                    onClick={() => startGame()}
                    disabled={game.players.length <= 1 ? true : false}
                  >
                    Start game
                  </button>
                )}
                <button
                  className="testToast"
                  onClick={() => toast("This is a test")}
                >
                  Test toast
                </button>
              </div>
              <div className="col presence">
                {game.players.length == 0 ? (
                  <h1>Waiting for players...</h1>
                ) : (
                  <>
                    <div className="players">
                      {game.players.map((player, index) => (
                        <>
                          <div
                            className="player"
                            key={`player-${index}`}
                            id={player.id}
                            style={{
                              "--player-color": player.color,
                            }}
                          >
                            {player.name}
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {game.gameStarted && (
        <>
          <div className="Game">
            <div className="wrapper">
              <div className="state">
                <div
                  className="player"
                  style={{
                    "--player-color":
                      game.state === "gameOver"
                        ? "var(--brand)"
                        : game.players[game.currentPlayerIndex].color,
                  }}
                >
                  {game.state == "pickCard" ? (
                    <>
                      <h1>
                        It's {game.players[game.currentPlayerIndex].name}'s turn
                      </h1>
                      <p>Waiting for player to pick a card...</p>
                    </>
                  ) : game.state === "cardPicked" ? (
                    <>
                      <p>
                        {game.players[game.currentPlayerIndex].name} picked{" "}
                        {game.state === "gameOver" && "the last card"}
                      </p>
                      <h1>{game.currentCard.name}</h1>
                      {game.state === "gameOver" && <>Game over!</>}
                    </>
                  ) : (
                    <>
                      <h1>Game over!</h1>
                    </>
                  )}
                </div>
                {game.state == "cardPicked" && (
                  <>
                    <div
                      className="rule"
                      style={{
                        "--player-color":
                          game.players[game.currentPlayerIndex].color,
                      }}
                    >
                      <p>{game.currentCard.ruleDesc}</p>
                    </div>
                    <img
                      src={game.currentCard.image}
                      alt={game.currentCard.name}
                      className="cardImg"
                    />
                  </>
                )}
                {game.state === "gameOver" && (
                  <>
                    <button>Restart game</button>
                  </>
                )}
              </div>
            </div>

            <div className="players">
              {game.players.map((player) => (
                <>
                  <div
                    className={`player ${
                      player.id === game.players[game.currentPlayerIndex].id
                        ? "active"
                        : ""
                    }`}
                    style={{ "--color": player.color }}
                  >
                    <p>{player.name}</p>
                  </div>
                </>
              ))}
            </div>

            <div className="status">
              <div className="stat id">
                <img src={qr} alt="" />
                <p>{game.id}</p>
              </div>
              <div className="stat cards">
                <img src={cardssvg} alt="" />
                <p>{cards.length - game.usedCards.length} Cards left</p>
              </div>
              {currentKing && (
                <div
                  className="stat king"
                  style={{ "--king-color": kingTimeout.color }}
                >
                  <img src={kingWhite} alt="" />
                  <span className="name">{kingTimeout.player}</span>
                  <span className="timeLeft">{kingTimeout.timeLeft}</span>
                </div>
              )}
            </div>

            <div className="controls">
              <GameControl
                classNeme="skip"
                onClick={() => channel.publish("finishTurn", "finishTurn")}
                icon={skip}
                tooltip="Skip player"
              />

              <GameControl
                className="lobby"
                onClick={() => setShowLobby(true)}
                icon={dashboard}
                tooltip="View lobby"
              />

              {currentKing && (
                <GameControl
                  className="revokeKing"
                  onClick={() => setCurrentKing(undefined)}
                  icon={kingBlack}
                  tooltip="Revoke the current king"
                />
              )}

              <GameControl
                className="restart"
                onClick={() => restartGame()}
                icon={reload}
                tooltip="Restart game"
              />
              <GameControl
                className="restart"
                onClick={() => toast("test")}
                icon={reload}
                tooltip="Restart game"
              />
            </div>
          </div>
        </>
      )}
      {/*{game.gameStarted ? (
        <div className="game">
          <p>Game started!</p>
          <p>{cards.length - game.usedCards.length} Cards left</p>
          {currentKing && (
            <>
              {kingTimeout.player} is the king for {kingTimeout.timeLeft}{" "}
              minutes.
            </>
          )}
          {game.state === "cardPicked" ? (
            <p>
              {activePlayer.name} drew a {game.currentCard.name}
            </p>
          ) : game.state === "pickCard" ? (
            <>
              <p>It's {activePlayer.name}'s turn</p>
            </>
          ) : (
            <>
              <p>Game over!</p>
              <button onClick={() => restartGame()}>Restart</button>
            </>
          )}
        </div>
      ) : (
        <p>Game is not started...</p>
      )}
      <h3>Players</h3>
      <ul>
        {game.players.map((player, index) => (
          <li key={`player-${index}`}>
            {player.name}{" "}
            <button onClick={() => removePlayer(player.id)}>X</button>
          </li>
        ))}
      </ul>
        <button onClick={() => startGame()}>Start game</button>*/}
    </div>
  );
};

export default Host;
