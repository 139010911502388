import React from "react";
import * as Tooltip from "@radix-ui/react-tooltip";

const GameControl = (props) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div
            className={`control ${props.className} ${props.info ? "info" : ""}`}
            onClick={(e) => {
              props.onClick(e);
            }}
          >
            <img src={props.icon} alt="" />
            {props.info && <p>{props.text}</p>}
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="TooltipContent" sideOffset={5}>
            {props.tooltip}
            <Tooltip.Arrow className="TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default GameControl;
